/**
 * @fileoverview MQTTConfig component that handles device registration and credential
 * management. Provides functionality for MQTT device registration, credential 
 * generation, and connection status monitoring with integrated instructions display.
 * 
 * @author Your Name
 * @license MIT
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  RefreshCw, 
  Copy, 
  Check,
  CheckCircle,
  XCircle,
  Eye,
  EyeOff
} from 'lucide-react';
import { Device } from '../../../types/device';
import MQTTInstructions from './instructions/MQTTInstructions1';

/**
 * Configuration constants for MQTT broker settings and credential management.
 * @const {Object}
 */
const MQTT_CONFIG = {
  /** MQTT broker hostname */
  BROKER: 'videobase-mqtt.heysalad.app',
  /** Standard MQTT port */
  STANDARD_PORT: '1883',
  /** TLS-enabled MQTT port */
  TLS_PORT: '8883',
  /** Length of generated passwords */
  PASSWORD_LENGTH: 16,
  /** Characters used in password generation */
  PASSWORD_CHARS: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
  /** Timeout for copy feedback in milliseconds */
  COPY_TIMEOUT_MS: 2000,
  /** Registration success message display duration */
  SUCCESS_TIMEOUT_MS: 5000
} as const;

/**
 * Props interface for MQTTConfig component.
 * @interface Props
 */
interface Props {
  /** Device to be configured */
  device: Device;
  /** MQTT username for authentication */
  mqttUsername: string;
  /** MQTT password for authentication */
  mqttPassword: string;
  /** Callback to update device credentials */
  onUpdateCredentials: (username: string, password: string) => Promise<void>;
  /** Callback to handle URL copying */
  onCopyUrl: (text: string) => Promise<void>;
  /** Flag indicating if TLS is enabled */
  isTLS: boolean;
}

/**
 * Interface for registration status state.
 * @interface RegistrationStatus
 */
interface RegistrationStatus {
  /** Type of status message */
  type: 'success' | 'error' | null;
  /** Status message content */
  message: string | null;
}

/**
 * MQTTConfig component handles device registration and displays connection details.
 *
 * @component
 * @param {Props} props - Component properties
 * @returns {JSX.Element} Rendered component
 */
export const MQTTConfig: React.FC<Props> = ({
  device,
  mqttUsername,
  mqttPassword,
  onUpdateCredentials,
  onCopyUrl,
  isTLS
}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus>({
    type: null,
    message: null
  });
  const [copied, setCopied] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  /**
   * Generates a cryptographically secure random password.
   * @return {string} Generated password string.
   */
  const generatePassword = (): string => {
    const array = new Uint8Array(MQTT_CONFIG.PASSWORD_LENGTH);
    crypto.getRandomValues(array);
    return Array.from(array)
      .map(x => MQTT_CONFIG.PASSWORD_CHARS[x % MQTT_CONFIG.PASSWORD_CHARS.length])
      .join('');
  };

  /**
   * Handles generation of new credentials.
   */
  const handleGenerateCredentials = async (): Promise<void> => {
    if (isRegistering) return;
    
    const newPassword = generatePassword();
    const newUsername = `device_${device.id}`;
    await onUpdateCredentials(newUsername, newPassword);
  };

  /**
   * Registers the device with the MQTT broker.
   */
  const registerDevice = async (): Promise<void> => {
    try {
      setIsRegistering(true);
      setRegistrationStatus({ type: null, message: null });

      const response = await fetch('https://videobase-device-registration.heysalad.app/api/v1/device/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          device_id: device.id,
          username: mqttUsername,
          password: mqttPassword
        })
      });

      const data = await response.json();

      if (data.status === 'success') {
        setRegistrationStatus({
          type: 'success',
          message: data.message || 'Device registered successfully'
        });
        setIsRegistered(true);

        setTimeout(() => {
          setRegistrationStatus({ type: null, message: null });
        }, MQTT_CONFIG.SUCCESS_TIMEOUT_MS);
      } else {
        throw new Error(data.message || 'Registration failed');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Registration failed';
      setRegistrationStatus({
        type: 'error',
        message: errorMessage
      });
      console.error('Registration error:', error);
    } finally {
      setIsRegistering(false);
    }
  };

  /**
   * Handles copying text to clipboard with feedback.
   * @param {string} text - Text to copy
   * @param {string} type - Type of content being copied
   */
  const handleCopy = async (text: string, type: string): Promise<void> => {
    try {
      if (type === 'url' && onCopyUrl) {
        await onCopyUrl(text);
      } else {
        await navigator.clipboard.writeText(text);
      }
      setCopied(type);
      setTimeout(() => setCopied(''), MQTT_CONFIG.COPY_TIMEOUT_MS);
    } catch (error) {
      console.error('Copy failed:', error);
    }
  };

  /**
   * Navigates to device listening page.
   */
  const handleListenClick = () => {
    navigate(`/devices/${device.id}/listening`);
  };

  // Construct broker URL based on TLS setting
  const brokerUrl = `${isTLS ? 'mqtts://' : 'mqtt://'}${MQTT_CONFIG.BROKER}:${
    isTLS ? MQTT_CONFIG.TLS_PORT : MQTT_CONFIG.STANDARD_PORT
  }`;

  return (
    <div className="space-y-6">
      <div className="card bg-base-100 shadow-sm">
        <div className="card-body">
          <div className="flex justify-between items-center">
            <h3 className="card-title text-lg">Connection Details</h3>
            {registrationStatus.type && (
              <div className={`alert ${registrationStatus.type === 'success' ? 'alert-success' : 'alert-error'} py-2 px-4`}>
                {registrationStatus.type === 'success' ? (
                  <CheckCircle className="w-5 h-5" />
                ) : (
                  <XCircle className="w-5 h-5" />
                )}
                <span>{registrationStatus.message}</span>
              </div>
            )}
          </div>

          <div className="space-y-4">
            {/* MQTT URL Field */}
            <div className="form-control">
              <label className="label">
                <span className="label-text font-medium">MQTT Broker URL</span>
                <span className="label-text-alt">{isTLS ? 'TLS Enabled' : 'Standard'}</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  value={brokerUrl}
                  readOnly
                  className="input input-bordered w-full font-mono text-sm"
                />
                <button 
                  onClick={() => handleCopy(brokerUrl, 'url')}
                  className="btn"
                >
                  {copied === 'url' ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
                </button>
              </div>
            </div>

            {/* Credentials Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Username Field */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-medium">Username</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    value={mqttUsername}
                    readOnly
                    className="input input-bordered w-full font-mono text-sm"
                  />
                  <button 
                    onClick={() => handleCopy(mqttUsername, 'username')}
                    className="btn"
                  >
                    {copied === 'username' ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
                  </button>
                </div>
              </div>

              {/* Password Field */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-medium">Password</span>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={mqttPassword}
                    readOnly
                    className="input input-bordered w-full font-mono text-sm"
                  />
                  <button
                    className="btn"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                  </button>
                  <button
                    className={`btn ${isRegistering ? 'loading' : ''}`}
                    onClick={handleGenerateCredentials}
                    disabled={isRegistering}
                  >
                    {!isRegistering && <RefreshCw className="w-4 h-4 mr-2" />}
                    <span>Generate</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Register Button */}
            {mqttPassword && !isRegistered && (
              <div className="mt-6 flex justify-end">
                <button
                  className={`btn btn-primary ${isRegistering ? 'loading' : ''}`}
                  onClick={registerDevice}
                  disabled={isRegistering}
                >
                  {isRegistering ? 'Registering...' : 'Register Device'}
                </button>
              </div>
            )}

            {/* Listen Button */}
            {isRegistered && (
              <div className="mt-6 flex justify-end">
                <button
                  className="btn btn-primary"
                  onClick={handleListenClick}
                >
                  Listen to Device
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Instructions Section */}
      {(isRegistered || mqttPassword) && (
        <MQTTInstructions
          deviceId={device.id}
          mqttUsername={mqttUsername}
          brokerUrl={brokerUrl}
          isTLS={isTLS}
        />
      )}
    </div>
  );
};

export default MQTTConfig;