import { FC, useState, useEffect } from 'react';
import { Copy, Plus, Activity, Settings, Globe, Network } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { LucideIcon } from 'lucide-react';
import { Device, DeviceType } from '../types/device';
import { deviceService } from '../services/deviceService';
import { DeviceForm } from '../components/devices/DeviceForm';
import DeviceCard from '../components/devices/DeviceCard';

const CheckLoading: FC = () => (
  <div className="flex justify-center items-center min-h-screen">
    <span className="loading loading-spinner loading-lg text-[#ed4c4c]"></span>
  </div>
);

const RECENT_ACTIVITIES = [
  { id: 1, deviceName: 'Kitchen Camera', event: 'Started streaming', time: '2 mins ago' },
  { id: 2, deviceName: 'Entry Sensor', event: 'Detected motion', time: '5 mins ago' },
  { id: 3, deviceName: 'Garage Camera', event: 'Stopped streaming', time: '15 mins ago' }
];

interface ConnectionCardProps {
  protocol: string;
  icon: LucideIcon;
  url: string;
  description: string;
}

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadDevices();
  }, []);

  const loadDevices = async () => {
    try {
      const data = await deviceService.getDevices();
      setDevices(data);
      setError(null);
    } catch (err) {
      console.error('Error loading devices:', err);
      setError('Failed to load devices');
    } finally {
      setLoading(false);
    }
  };

  const handleDeviceUpdate = async (deviceId: string, updates: Partial<Device>): Promise<void> => {
    try {
      await deviceService.updateDevice(deviceId, updates);
      await loadDevices();
      setError(null);
    } catch (err) {
      console.error('Error updating device:', err);
      setError('Failed to update device');
    }
  };

  const handleDeviceDelete = async (deviceId: string): Promise<void> => {
    try {
      await deviceService.deleteDevice(deviceId);
      setDevices(devices.filter(device => device.id !== deviceId));
      setError(null);
    } catch (err) {
      console.error('Error deleting device:', err);
      setError('Failed to delete device');
    }
  };

  const handleCreateDevice = async (formData: { name: string; deviceType: DeviceType }): Promise<void> => {
    try {
      const newDevice = await deviceService.createDevice(formData.name, formData.deviceType);
      setDevices(prevDevices => [newDevice, ...prevDevices]);
      setShowForm(false);
      setError(null);
    } catch (err) {
      console.error('Error creating device:', err);
      setError('Failed to create device');
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const ConnectionCard: FC<ConnectionCardProps> = ({ protocol, icon: Icon, url, description }) => (
    <div className="bg-white border border-gray-200 rounded-lg p-3 hover:bg-gray-50 transition-all h-full">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 rounded bg-white border border-gray-200 flex items-center justify-center">
            <Icon className="w-4 h-4 text-[#ed4c4c]" />
          </div>
          <h3 className="text-sm font-medium text-gray-900">{protocol}</h3>
        </div>
        <button 
          onClick={() => handleCopyToClipboard(url)}
          className="btn btn-ghost btn-xs hover:bg-gray-100"
        >
          <Copy className="w-3 h-3" />
        </button>
      </div>
      <p className="text-xs text-gray-600 mb-2">{description}</p>
      <code className="text-xs bg-gray-50 rounded p-2 block border border-gray-200 overflow-x-auto">
        {url}
      </code>
    </div>
  );

  if (loading) {
    return <CheckLoading />;
  }

  return (
    <div className="p-4 max-w-[1600px] mx-auto">
      {/* Header and Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
        <div className="md:col-span-2 lg:col-span-5 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
          <button 
            onClick={() => setShowForm(true)}
            className="btn bg-[#ed4c4c] hover:bg-[#faa09a] text-white border-none btn-sm gap-2"
          >
            <Plus className="w-4 h-4" />
            Add Device
          </button>
        </div>
        
        {/* Compact Stats */}
        <div className="card bg-white border border-gray-200">
          <div className="card-body p-3">
            <div className="flex justify-between items-center mb-1">
              <h3 className="text-sm font-medium text-gray-700">Storage</h3>
              <span className="text-xs text-gray-500">1GB</span>
            </div>
            <div className="w-full bg-gray-100 rounded-full h-2">
              <div className="bg-[#ed4c4c] h-2 rounded-full w-[40%]"></div>
            </div>
          </div>
        </div>

        <div className="card bg-white border border-gray-200">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-700">Active</h3>
              <span className="text-[#ed4c4c] font-semibold">12</span>
            </div>
            <p className="text-xs text-gray-500">3 streaming</p>
          </div>
        </div>

        <div className="card bg-white border border-gray-200">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-700">Data</h3>
              <span className="text-[#ed4c4c] font-semibold">2.4GB</span>
            </div>
            <p className="text-xs text-gray-500">Last 24h</p>
          </div>
        </div>

        <div className="card bg-white border border-gray-200">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-700">Uptime</h3>
              <span className="text-[#ed4c4c] font-semibold">99.9%</span>
            </div>
            <p className="text-xs text-gray-500">30 days</p>
          </div>
        </div>

        <div className="card bg-white border border-gray-200">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-700">Status</h3>
              <span className="badge badge-success badge-sm">Healthy</span>
            </div>
            <p className="text-xs text-gray-500">All systems</p>
          </div>
        </div>
      </div>

      {/* Main Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
        {/* Devices Section */}
        <div className="lg:col-span-12 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-900">My Devices</h2>
            <button 
              onClick={() => navigate('/devices')}
              className="btn btn-ghost btn-xs text-[#ed4c4c] hover:bg-gray-100 gap-1"
            >
              <Settings className="w-3 h-3" />
              Manage All Devices
            </button>
          </div>
          
          {error && (
            <div className="alert alert-error mb-4">
              <span>{error}</span>
            </div>
          )}

          {devices.length === 0 ? (
            <div className="card bg-white border border-gray-200">
              <div className="card-body items-center text-center">
                <h3 className="card-title">No Devices Found</h3>
                <p className="text-gray-600">Get started by adding your first device</p>
                <button 
                  className="btn bg-[#ed4c4c] hover:bg-[#faa09a] text-white border-none mt-4"
                  onClick={() => setShowForm(true)}
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Your First Device
                </button>
              </div>
            </div>
          ) : (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {devices.map(device => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onUpdate={(updates) => handleDeviceUpdate(device.id, updates)}
                  onDelete={() => handleDeviceDelete(device.id)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Recent Activity */}
        <div className="lg:col-span-3 card bg-white border border-gray-200">
          <div className="card-body p-4">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
            <div className="space-y-3">
              {RECENT_ACTIVITIES.map(activity => (
                <div key={activity.id} className="flex gap-2">
                  <div className="w-1.5 h-1.5 mt-1.5 rounded-full bg-[#ed4c4c]"></div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">{activity.deviceName}</h3>
                    <p className="text-xs text-gray-600">{activity.event}</p>
                    <span className="text-xs text-gray-500">{activity.time}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Connection Info */}
        <div className="lg:col-span-9 card bg-white border border-gray-200">
          <div className="card-body p-4">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Connection Info</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              <ConnectionCard 
                protocol="HTTPS"
                icon={Globe}
                url="https://videobase-api.heysalad.app/v1/devices/data"
                description="RESTful API endpoint"
              />
              <ConnectionCard 
                protocol="WebSocket"
                icon={Activity}
                url="wss://videobase-websocket.heysalad.app/ws"
                description="Real-time communication"
              />
              <ConnectionCard 
                protocol="MQTTS"
                icon={Network}
                url="mqtts://videobase-mqtt.heysalad.app:8883"
                description="Secure MQTT (TLS)"
              />
              <ConnectionCard 
                protocol="MQTT"
                icon={Network}
                url="mqtt://videobase-mqtt.heysalad.app:1883"
                description="Standard MQTT"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Device Form Modal */}
      {showForm && (
        <DeviceForm
          onSubmit={handleCreateDevice}
          onCancel={() => setShowForm(false)}
        />
      )}
    </div>
  );
};

export default Dashboard;