// src/components/landing/CallToAction.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

/**
 * Call to Action section component for the landing page.
 * Displays a prominent section encouraging users to sign up.
 *
 * @returns {JSX.Element} The rendered CallToAction component
 */
const CallToAction: React.FC = () => {
  return (
    <div className="bg-[#ed4c4c] py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-2xl sm:text-3xl font-bold text-white mb-6">
          Ready to Transform Your IoT Vision?
        </h2>
        <Link 
          to="/signup" 
          className="btn bg-white text-[#ed4c4c] hover:bg-gray-100 transform transition hover:scale-105"
        >
          Start Free Trial
          <ChevronRight className="ml-2" />
        </Link>
      </div>
    </div>
  );
};

export default CallToAction;