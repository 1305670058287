// src/components/landing/CheckLoading.tsx
import React from 'react';
import { Loader2 } from 'lucide-react';
import icon from '../../assets/icon.svg';

const CheckLoading: React.FC = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center">
      {/* Logo Section */}
      <div className="flex items-center justify-center mb-8">
        <img src={icon} alt="Videobase Logo" className="w-12 h-12 mr-3" />
        <h1 className="text-3xl font-bold text-black">VideoBase</h1>
      </div>

      {/* Loading Animation */}
      <div className="flex flex-col items-center">
        <Loader2 className="w-12 h-12 text-[#ed4c4c] animate-spin mb-4" />
        <p className="text-lg text-gray-600 font-medium">
          Setting up your BaseCamp
        </p>
      </div>
    </div>
  );
};

export default CheckLoading;