// src/pages/Devices/DeviceInfo.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deviceService } from '../../services/deviceService';
import { Device } from '../../types/device';
import { DeviceConfig } from '../../components/devices/DeviceConfig';

const DeviceInfo: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [device, setDevice] = useState<Device | null>(null);
  const [showConfig, setShowConfig] = useState(false);

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      try {
        const deviceData = await deviceService.getDeviceById(deviceId!);
        setDevice(deviceData);
      } catch (err) {
        console.error('Error fetching device info:', err);
      }
    };
    fetchDeviceInfo();
  }, [deviceId]);

  const handleDeviceUpdate = async (updates: Partial<Device>) => {
    try {
      await deviceService.updateDevice(deviceId!, updates);
      setDevice((prevDevice) => (prevDevice ? { ...prevDevice, ...updates } : null));
    } catch (err) {
      console.error('Error updating device:', err);
    }
  };

  const handleDeviceDelete = async () => {
    try {
      await deviceService.deleteDevice(deviceId!);
      // Redirect to devices page or handle deletion
    } catch (err) {
      console.error('Error deleting device:', err);
    }
  };

  if (!device) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Device Info</h1>
        <button
          onClick={() => setShowConfig(true)}
          className="btn btn-primary"
        >
          Configure Device
        </button>
      </div>

      {showConfig && (
        <DeviceConfig
          device={device}
          onClose={() => setShowConfig(false)}
          onUpdate={handleDeviceUpdate}
          onDelete={handleDeviceDelete}
        />
      )}
    </div>
  );
};

export default DeviceInfo;