import React, { useState } from 'react';
import { X, Plus } from 'lucide-react';
import { DeviceType } from '../../types/device';

// Import device images
import recameraImg from '../../assets/recamera-photo.svg';
import groveAiImg from '../../assets/grove-ai.svg';
import sx1262Img from '../../assets/sx1262.svg';
import agriweissE5Img from '../../assets/agriweiss_e5.svg';
import esp32cImg from '../../assets/esp32c.svg';
import esp32cSimImg from '../../assets/esp32c-sim800l.svg';

interface DeviceFormProps {
  onSubmit: (data: { name: string; deviceType: DeviceType }) => void;
  onCancel: () => void;
}

interface DeviceOption {
  type: DeviceType;
  name: string;
  image: string;
  description: string;
}

const deviceOptions: DeviceOption[] = [
  {
    type: 'reCamera',
    name: 'Seeedstudio reCamera',
    image: recameraImg,
    description: 'AI-powered camera for edge computing'
  },
  {
    type: 'groveAI',
    name: 'Seeedstudio Grove AI v2',
    image: groveAiImg,
    description: 'Vision AI development kit'
  },
  {
    type: 'xiaoLora',
    name: 'XIAO ESP32S3 LoRa Kit',
    image: sx1262Img,
    description: 'LoRa connectivity solution'
  },
  {
    type: 'agriweissE5',
    name: 'AGRIWEISS e5 Vision Kit',
    image: agriweissE5Img,
    description: 'Agriculture monitoring solution'
  },
  {
    type: 'xiaoESP32C3',
    name: 'XIAO ESP32C3 Vision Kit',
    image: esp32cImg,
    description: 'Compact vision development kit'
  },
  {
    type: 'xiaoESP32C3Cellular',
    name: 'XIAO ESP32C3 Cellular Kit',
    image: esp32cSimImg,
    description: 'Cellular IoT solution'
  }
];

export const DeviceForm: React.FC<DeviceFormProps> = ({ onSubmit, onCancel }) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  const [name, setName] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedDevice) return;
    onSubmit({ name, deviceType: selectedDevice });
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-end">
      {/* Modal Backdrop */}
      <div 
        className="absolute inset-0 bg-black/20 backdrop-blur-sm"
        onClick={onCancel}
      />
      
      {/* Sliding Panel */}
      <div className="relative w-[600px] bg-white shadow-2xl animate-slide-in-right flex flex-col">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Plus className="w-5 h-5" />
            <h2 className="text-xl font-bold text-gray-900">Add New Device</h2>
          </div>
          <button 
            onClick={onCancel}
            className="btn btn-ghost btn-sm btn-circle"
          >
            <X className="w-4 h-4" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Device Selection */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900">Select Device Type</h3>
                <div className="grid grid-cols-2 gap-4">
                  {deviceOptions.map((device) => (
                    <div
                      key={device.type}
                      className={`border rounded-lg p-4 cursor-pointer hover:border-[#ed4c4c] transition-colors ${
                        selectedDevice === device.type 
                          ? 'border-[#ed4c4c] bg-[#ffd0cd]/10' 
                          : 'border-gray-200'
                      }`}
                      onClick={() => setSelectedDevice(device.type)}
                    >
                      <div className="flex flex-col items-center text-center">
                        <img 
                          src={device.image} 
                          alt={device.name}
                          className="w-24 h-24 object-contain mb-3"
                        />
                        <h4 className="font-medium text-gray-900">{device.name}</h4>
                        <p className="text-sm text-gray-500 mt-1">{device.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Device Name Input */}
              {selectedDevice && (
                <div className="space-y-4 animate-slide-down">
                  <h3 className="text-lg font-semibold text-gray-900">Device Details</h3>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text text-gray-700">Device Name</span>
                      {!name && <span className="label-text text-[#ed4c4c]">Required</span>}
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className={`input w-full transition-all duration-200 ${
                        !name 
                          ? 'input-error border-[#ed4c4c] focus:outline-none focus:border-[#ed4c4c] focus:ring-2 focus:ring-[#ffd0cd]' 
                          : 'input-bordered focus:border-[#ed4c4c] focus:ring-2 focus:ring-[#ffd0cd]'
                      }`}
                      placeholder="Enter a name for your device"
                      required
                      autoFocus
                    />
                    <label className="label">
                      <span className="label-text-alt text-gray-500">
                        Give your device a unique and memorable name
                      </span>
                    </label>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>

        {/* Footer */}
        <div className="border-t border-gray-200 p-4 flex justify-end gap-2">
          <button 
            onClick={onCancel} 
            className="btn btn-ghost"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="btn bg-[#ed4c4c] hover:bg-[#faa09a] text-white border-none"
            disabled={!selectedDevice || !name}
          >
            Create Device
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeviceForm;