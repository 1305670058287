// src/components/auth/LoginForm.tsx
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { Mail, Lock, AlertCircle } from 'lucide-react';
import icon from '../../assets/icon.svg';
import CheckLoading from '../landing/CheckLoading';

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const { signIn } = useAuth();
  const { checkSubscription } = useSubscription();
  const navigate = useNavigate();
  const location = useLocation();

  const BILLING_API = import.meta.env.VITE_PROD_BILLING_API;

  const checkUserSubscriptionAndRedirect = async (userEmail: string) => {
    try {
      setIsChecking(true);
      const response = await fetch(
        `${BILLING_API}/api/subscriptions/status/${encodeURIComponent(userEmail)}`,
        {
          credentials: 'include',
        }
      );

      const data = await response.json();
      console.log('Subscription check response:', data);

      // Update subscription context
      await checkSubscription();

      // Add a minimal delay to ensure loading state is visible
      await new Promise(resolve => setTimeout(resolve, 1000));

      // If user has active subscription, send them to dashboard
      if (data.hasActiveSubscription) {
        const returnUrl = location.state?.returnUrl;
        navigate(returnUrl || '/dashboard', { replace: true });
      } else {
        // If no active subscription, send them to pricing
        navigate('/pricing', { replace: true });
      }
    } catch (err) {
      console.error('Subscription check error:', err);
      // On error, default to pricing page
      navigate('/pricing', { replace: true });
    } finally {
      setIsChecking(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);

      // Sign in user
      await signIn(email, password);
      console.log('Sign in successful');

      // After successful sign in, check subscription and redirect
      await checkUserSubscriptionAndRedirect(email);
    } catch (err) {
      console.error('Login error:', err);
      setError(err instanceof Error ? err.message : 'Failed to sign in');
      setIsChecking(false);
    } finally {
      setLoading(false);
    }
  };

  if (isChecking) {
    return <CheckLoading />;
  }

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center px-4">
      <div className="max-w-md w-full">
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <Link 
              to="/" 
              className="flex items-center justify-center mb-4 hover:opacity-80 transition-opacity"
              replace={true}
            >
              <img src={icon} alt="Videobase Logo" className="w-8 h-8 mr-2" />
              <h1 className="text-2xl font-bold text-black">Videobase</h1>
            </Link>
            
            <h2 className="card-title justify-center text-xl mb-4">
              Sign in to Videobase
            </h2>

            {error && (
              <div className="alert alert-error">
                <AlertCircle className="w-5 h-5" />
                <span>{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <div className="input-group">
                  <span><Mail size={20} /></span>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input input-bordered w-full"
                    placeholder="Enter your email"
                    required
                    autoComplete="email"
                    disabled={loading}
                  />
                </div>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <div className="input-group">
                  <span><Lock size={20} /></span>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input input-bordered w-full"
                    placeholder="Enter your password"
                    required
                    autoComplete="current-password"
                    disabled={loading}
                  />
                </div>
              </div>

              <button
                type="submit"
                className={`btn btn-primary w-full ${loading ? 'loading' : ''}`}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span className="loading loading-spinner"></span>
                    Signing in...
                  </>
                ) : (
                  'Sign in'
                )}
              </button>
            </form>

            <div className="divider">OR</div>

            <p className="text-center">
              Don't have an account?{' '}
              <Link 
                to="/signup" 
                className="link link-primary"
                replace={true}
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;