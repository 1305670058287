// src/components/landing/BuyMeCoffeeWidget.tsx
import React, { useEffect } from 'react';

/**
 * Configuration options for the Buy Me a Coffee widget.
 */
interface BMCConfig {
  /** Unique identifier for the Buy Me a Coffee account */
  id: string;
  /** Custom message to display in the widget */
  message: string;
  /** Description for the support button */
  description: string;
  /** Color theme for the widget */
  color: string;
  /** Widget position (Left or Right) */
  position: 'Left' | 'Right';
  /** Margin from the right/left edge in pixels */
  xMargin: number;
  /** Margin from the bottom edge in pixels */
  yMargin: number;
}

/**
 * Buy Me a Coffee widget component that loads and initializes the BMC widget.
 * 
 * @returns {JSX.Element} The rendered Buy Me a Coffee widget component
 */
const BuyMeCoffeeWidget: React.FC = () => {
  useEffect(() => {
    // Widget configuration following Google style guidelines
    const config: BMCConfig = {
      id: 'videobase.cloud',
      message: 'Thank you for visiting Videobase, please support our efforts.',
      description: 'Support me on Buy me a coffee!',
      color: '#FF5F5F',
      position: 'Right',
      xMargin: 18,
      yMargin: 18,
    };

    /**
     * Creates and loads the BMC widget script.
     */
    const loadBMCWidget = (): void => {
      // Remove any existing widget script
      const existingWidget = document.getElementById('bmc-widget-script');
      if (existingWidget) {
        existingWidget.remove();
      }

      // Create new script element
      const script = document.createElement('script');
      script.id = 'bmc-widget-script';
      script.async = true;
      script.setAttribute('data-name', 'BMC-Widget');
      script.setAttribute('data-cfasync', 'false');
      script.setAttribute('data-id', config.id);
      script.setAttribute('data-description', config.description);
      script.setAttribute('data-message', config.message);
      script.setAttribute('data-color', config.color);
      script.setAttribute('data-position', config.position);
      script.setAttribute('data-x_margin', config.xMargin.toString());
      script.setAttribute('data-y_margin', config.yMargin.toString());
      script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js';

      // Append script to document
      document.body.appendChild(script);
    };

    // Load the widget
    loadBMCWidget();

    // Cleanup function
    return () => {
      const script = document.getElementById('bmc-widget-script');
      if (script) {
        script.remove();
      }
    };
  }, []);

  // Component doesn't render any visible elements
  return null;
};

export default BuyMeCoffeeWidget;