/**
 * @fileoverview Device Configuration Component providing a comprehensive interface
 * for managing device settings and monitoring capabilities across multiple protocols
 * (MQTT, HTTPS, WebSocket). Handles protocol selection, credential management,
 * and real-time monitoring setup.
 *
 * @author Your Name
 * @license MIT
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, X, Radio } from 'lucide-react';
import type { Device, Protocol } from '../../types/device';
import { 
  MQTTConfig, 
  HTTPSConfig, 
  WebSocketConfig,
  NodeREDSection,
  DeviceInfoSection, 
  ProtocolToggle 
} from './config';

/**
 * Configuration constants for different protocols.
 */
const PROTOCOLS = {
  /** Supported monitoring protocols */
  MONITORABLE: ['mqtt', 'mqtts', 'websocket'] as const,
  /** Configuration timeouts */
  TIMEOUTS: {
    SAVE: 2000,
    ERROR: 5000
  }
} as const;

/**
 * Props interface for the DeviceConfig component.
 */
interface DeviceConfigProps {
  /** Device being configured */
  device: Device;
  /** Optional callback function to close the configuration modal */
  onClose?: () => void;
  /** Callback function to update device settings */
  onUpdate: (updates: Partial<Device>) => Promise<void>;
  /** Callback function to delete the device */
  onDelete: () => Promise<void>;
}

/**
 * DeviceConfig Component handles device configuration and monitoring setup.
 * Supports multiple protocols with appropriate security options and monitoring capabilities.
 */
export const DeviceConfig: React.FC<DeviceConfigProps> = ({
  device,
  onClose,
  onUpdate,
  onDelete,
}): JSX.Element => {
  const navigate = useNavigate();
  
  // State Management
  const [name, setName] = useState<string>(device.name);
  const [protocol, setProtocol] = useState<Protocol>(device.protocol || 'mqtt');
  const [isTLS, setIsTLS] = useState<boolean>(protocol === 'mqtts');
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  /**
   * Determines if the current protocol supports monitoring.
   * @return {boolean} True if monitoring is supported.
   */
  const isMonitoringSupported = (): boolean => {
    return PROTOCOLS.MONITORABLE.includes(protocol as typeof PROTOCOLS.MONITORABLE[number]);
  };

  /**
   * Handles saving device configuration changes.
   */
  const handleSave = async (): Promise<void> => {
    try {
      setSaving(true);
      setError(null);
      
      await onUpdate({
        name,
        protocol: isTLS && protocol === 'mqtt' ? 'mqtts' : protocol
      });
      
      onClose?.();
    } catch (err) {
      console.error('Error saving device config:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  /**
   * Handles navigation to device monitoring.
   */
  const handleNavigateToMonitoring = () => {
    navigate(`/devices/${device.id}/listening`);
  };

  /**
   * Handles copying text to clipboard.
   * @param {string} text - Text to copy.
   */
  const handleCopy = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  /**
   * Handles copying Node-RED code.
   * @param {string} code - Code to copy.
   */
  const handleCopyNodeREDCode = (code: string): void => {
    handleCopy(code).catch(err => {
      console.error('Failed to copy Node-RED code:', err);
    });
  };

  /**
   * Updates MQTT credentials for the device.
   * @param {string} username - New MQTT username.
   * @param {string} password - New MQTT password.
   */
  const handleUpdateMQTTCredentials = async (
    username: string,
    password: string
  ): Promise<void> => {
    await onUpdate({
      mqttUsername: username,
      mqttPassword: password,
      protocol: isTLS ? 'mqtts' : 'mqtt'
    });
  };

  /**
   * Updates HTTPS API key for the device.
   * @param {string} apiKey - New API key.
   */
  const handleUpdateHTTPSKey = async (apiKey: string): Promise<void> => {
    await onUpdate({
      clientId: apiKey,
      protocol: 'https'
    });
  };

  /**
   * Renders the monitoring button if supported by the protocol.
   */
  const renderMonitoringButton = (): JSX.Element | null => {
    if (!isMonitoringSupported()) return null;

    const hasCredentials = protocol === 'mqtt' 
      ? !!device.mqttPassword
      : !!device.clientId;

    if (!hasCredentials) return null;

    return (
      <button
        className="btn btn-primary btn-sm"
        onClick={handleNavigateToMonitoring}
        aria-label="Monitor device connection"
      >
        <Radio className="w-4 h-4 mr-2" />
        Monitor Device
      </button>
    );
  };

  /**
   * Renders the appropriate configuration component based on the selected protocol.
   */
  const renderProtocolConfig = (): JSX.Element => {
    switch (protocol) {
      case 'mqtt':
      case 'mqtts':
        return (
          <div className="card bg-base-100">
            <div className="card-body space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="card-title">MQTT Configuration</h3>
                {renderMonitoringButton()}
              </div>
              <MQTTConfig
                device={device}
                mqttUsername={device.mqttUsername || `device_${device.id}`}
                mqttPassword={device.mqttPassword || ''}
                onUpdateCredentials={handleUpdateMQTTCredentials}
                onCopyUrl={handleCopy}
                isTLS={isTLS}
              />
            </div>
          </div>
        );
      
      case 'https':
        return (
          <div className="card bg-base-100">
            <div className="card-body space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="card-title">HTTPS Configuration</h3>
                {renderMonitoringButton()}
              </div>
              <HTTPSConfig
                device={device}
                onUpdateApiKey={handleUpdateHTTPSKey}
                onCopyUrl={handleCopy}
              />
            </div>
          </div>
        );
      
      case 'websocket':
        return (
          <div className="card bg-base-100">
            <div className="card-body space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="card-title">WebSocket Configuration</h3>
                {renderMonitoringButton()}
              </div>
              <WebSocketConfig
                device={device}
                onCopyUrl={handleCopy}
              />
            </div>
          </div>
        );
      
      default:
        return (
          <div className="alert alert-warning" role="alert">
            <span>Please select a protocol to configure</span>
          </div>
        );
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-end">
      {/* Modal Backdrop */}
      <div 
        className="absolute inset-0 bg-black/20 backdrop-blur-sm"
        onClick={onClose}
        aria-label="Close configuration panel"
        role="presentation"
      />
      
      {/* Configuration Panel */}
      <div className="relative w-[600px] bg-base-100 shadow-2xl animate-slide-in-right flex flex-col">
        {/* Header */}
        <div className="px-6 py-4 border-b flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Settings className="w-5 h-5" />
            <h2 className="text-xl font-bold">Device Configuration</h2>
          </div>
          {onClose && (
            <button 
              onClick={onClose}
              className="btn btn-ghost btn-sm btn-circle"
              aria-label="Close configuration panel"
            >
              <X className="w-4 h-4" />
            </button>
          )}
        </div>

        {/* Content Area */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6 space-y-6">
            {error && (
              <div className="alert alert-error" role="alert">
                <span>{error}</span>
              </div>
            )}

            <DeviceInfoSection
              device={device}
              name={name}
              onNameChange={setName}
            />

            <ProtocolToggle
              selectedProtocol={protocol}
              onChange={setProtocol}
              onTLSChange={setIsTLS}
              isTLS={isTLS}
            />

            {renderProtocolConfig()}

            <NodeREDSection
              code={`// Node-RED function for ${device.id}\n// Protocol: ${protocol}`}
              onCopyCode={handleCopyNodeREDCode}
              protocol={protocol}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="border-t p-4 flex justify-between">
          <button 
            className="btn btn-error"
            onClick={onDelete}
            disabled={saving}
            aria-label="Delete device"
          >
            Delete Device
          </button>
          <div className="flex gap-2">
            <button
              className="btn btn-ghost"
              onClick={onClose}
              disabled={saving}
              aria-label="Cancel changes"
            >
              Cancel
            </button>
            <button
              className={`btn btn-primary ${saving ? 'loading' : ''}`}
              onClick={handleSave}
              disabled={saving}
              aria-label="Save changes"
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceConfig;