// src/pages/Landing.tsx
import React from 'react';
import Navigation from '../components/landing/Navigation';
import Hero from '../components/landing/Hero';
import Features from '../components/landing/Features';
import Stats from '../components/landing/Stats';
import Devices from '../components/landing/Devices';
import Partners from '../components/landing/Partners';
import CallToAction from '../components/landing/CallToAction';
import Footer from '../components/landing/Footer';
import BuyMeCoffeeWidget from '../components/landing/BuyMeCoffeeWidget';

// Asset imports
import icon from '../assets/icon.svg';
import recameraImg from '../assets/recamera-photo.svg';
import groveAiImg from '../assets/grove-ai.svg';
import sx1262Img from '../assets/sx1262.svg';
import agriweissE5Img from '../assets/agriweiss_e5.svg';
import esp32c3Img from '../assets/esp32c.svg';
import esp32cSim800lImg from '../assets/esp32c-sim800l.svg';

/**
 * Interface for hero section content.
 */
interface HeroContent {
  readonly phrases: string[];
  readonly subPhrases: string[];
}

/**
 * Main landing page component.
 * Combines and orders all landing page sections.
 *
 * Section order:
 * 1. Navigation
 * 2. Hero
 * 3. Features
 * 4. Devices
 * 5. Partners
 * 6. Stats
 * 7. Call to Action
 * 8. Footer
 *
 * @returns {JSX.Element} The rendered Landing page
 */
const Landing: React.FC = () => {
  const heroContent: HeroContent = {
    phrases: [
      "Simplify IoT Video Management",
      "Streamline Device Deployment",
      "Analyze Vision AI Models",
      "Scale Your IoT Fleet"
    ],
    subPhrases: [
      "with Vision AI",
      "in Minutes",
      "with Ease",
      "Seamlessly"
    ]
  };

  return (
    <div className="min-h-screen">
      {/* Navigation */}
      <Navigation icon={icon} />

      {/* Hero Section */}
      <Hero 
        phrases={heroContent.phrases} 
        subPhrases={heroContent.subPhrases} 
      />

      {/* Features Section */}
      <Features />

      {/* Supported Devices Section */}
      <Devices 
        recameraImg={recameraImg}
        groveAiImg={groveAiImg}
        sx1262Img={sx1262Img}
        agriweissE5Img={agriweissE5Img}
        esp32c3Img={esp32c3Img}
        esp32cSim800lImg={esp32cSim800lImg}
      />

      {/* Industry Partners Section */}
      <Partners />

      {/* Statistics Section */}
      <Stats />

      {/* Call to Action Section */}
      <CallToAction />

      {/* Footer */}
      <Footer icon={icon} />

      {/* Buy Me Coffee Widget */}
      <BuyMeCoffeeWidget />
    </div>
  );
};

export default Landing;