// src/contexts/SubscriptionContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

interface SubscriptionContextType {
  hasActiveSubscription: boolean;
  subscriptionPlan: string | null;
  userPlanName: string;
  isLoading: boolean;
  checkSubscription: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType>({
  hasActiveSubscription: false,
  subscriptionPlan: null,
  userPlanName: 'No Active Plan',
  isLoading: true,
  checkSubscription: async () => {},
});

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState({
    hasActiveSubscription: false,
    subscriptionPlan: null as string | null,
    userPlanName: 'No Active Plan'
  });

  const BILLING_API = import.meta.env.VITE_PROD_BILLING_API;

  const checkSubscription = async () => {
    if (!user?.email) {
      setSubscriptionData({
        hasActiveSubscription: false,
        subscriptionPlan: null,
        userPlanName: 'No Active Plan'
      });
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `${BILLING_API}/api/subscriptions/status/${encodeURIComponent(user.email)}`,
        {
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch subscription status');
      }

      const data = await response.json();
      console.log('Subscription data:', data);

      setSubscriptionData({
        hasActiveSubscription: data.hasActiveSubscription,
        subscriptionPlan: data.subscriptionPlan,
        userPlanName: data.userPlanName || 'No Active Plan'
      });
    } catch (error) {
      console.error('Subscription check error:', error);
      setSubscriptionData({
        hasActiveSubscription: false,
        subscriptionPlan: null,
        userPlanName: 'No Active Plan'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Check subscription when user changes
  useEffect(() => {
    checkSubscription();
  }, [user]);

  const value = {
    ...subscriptionData,
    isLoading,
    checkSubscription
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};