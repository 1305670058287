// src/pages/BillingTest.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSubscription } from '../contexts/SubscriptionContext';
import { Loader2, AlertCircle } from 'lucide-react';

const BillingTest: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { checkSubscription } = useSubscription();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<any>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);

  // Use production billing API
  const BILLING_API = import.meta.env.VITE_PROD_BILLING_API;
  const PRO_PRICE_ID = import.meta.env.VITE_STRIPE_PRO_PRICE_ID;

  // Check subscription status on component mount
  useEffect(() => {
    const checkStatus = async () => {
      if (user?.email) {
        try {
          const response = await fetch(
            `${BILLING_API}/api/subscriptions/status/${encodeURIComponent(user.email)}`,
            {
              credentials: 'include',
            }
          );
          const data = await response.json();
          console.log('Subscription status:', data);
          setSubscriptionStatus(data);

          // Update global subscription context
          await checkSubscription();

          // If user has active subscription and coming from certain paths, redirect to dashboard
          if (data.hasActiveSubscription && location.pathname === '/pricing') {
            navigate('/dashboard', { replace: true });
          }
        } catch (err) {
          console.error('Status check error:', err);
        }
      }
    };

    checkStatus();
  }, [user, BILLING_API, navigate, checkSubscription]);

  const handleTestSubscription = async () => {
    if (!user) {
      setError('Please login first');
      return;
    }

    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      let endpoint = subscriptionStatus?.hasActiveSubscription 
        ? 'create-portal-session'
        : 'create-checkout-session';

      console.log('Making request to:', `${BILLING_API}/api/subscriptions/${endpoint}`);
      
      const response = await fetch(
        `${BILLING_API}/api/subscriptions/${endpoint}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            endpoint === 'create-portal-session' 
              ? {
                  userId: user.id,
                  email: user.email,
                }
              : {
                  priceId: PRO_PRICE_ID,
                  userId: user.id,
                  customerEmail: user.email,
                }
          ),
          credentials: 'include',
        }
      );

      const data = await response.json();
      console.log('API Response:', data);
      setResponse(data);

      if (!response.ok) {
        throw new Error(data.error || 'Failed to create session');
      }

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Subscription test error:', err);
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow">
      <h1 className="text-2xl font-bold mb-4">Billing API Test</h1>
      
      <div className="mb-6 p-4 bg-gray-50 rounded">
        <h2 className="text-lg font-semibold mb-2">Configuration</h2>
        <div className="space-y-2 text-sm font-mono">
          <p>API URL: {BILLING_API}</p>
          <p>Price ID: {PRO_PRICE_ID}</p>
        </div>
      </div>

      {user ? (
        <div className="mb-4 p-4 bg-green-50 rounded">
          <p className="text-green-700">✓ Logged in as: {user.email}</p>
          <p className="text-sm text-gray-600 mt-1">User ID: {user.id}</p>
          {subscriptionStatus && (
            <div className="mt-2 pt-2 border-t border-green-100">
              <p className="text-sm font-semibold">
                Subscription Status: {' '}
                <span className={subscriptionStatus.hasActiveSubscription ? 'text-green-600' : 'text-yellow-600'}>
                  {subscriptionStatus.status}
                </span>
              </p>
              {subscriptionStatus.subscriptionId && (
                <>
                  <p className="text-xs text-gray-600 mt-1">
                    Subscription ID: {subscriptionStatus.subscriptionId}
                  </p>
                  <p className="text-xs text-gray-600 mt-1">
                    Plan: {subscriptionStatus.userPlanName}
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="mb-4 p-4 bg-yellow-50 rounded flex items-center">
          <AlertCircle className="h-5 w-5 text-yellow-500 mr-2" />
          <p className="text-yellow-700">Please login to test the billing API</p>
        </div>
      )}

      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded">
          <h3 className="font-semibold">Error:</h3>
          <p>{error}</p>
        </div>
      )}

      {response && (
        <div className="mb-4 p-4 bg-gray-50 rounded">
          <h3 className="font-semibold mb-2">API Response:</h3>
          <pre className="text-sm overflow-auto bg-gray-100 p-2 rounded">
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}

      <button
        onClick={handleTestSubscription}
        disabled={loading || !user}
        className={`
          w-full py-3 px-4 rounded-lg
          bg-[#ed4c4c] text-white hover:bg-[#c43c3c]
          transition-colors
          disabled:opacity-50 disabled:cursor-not-allowed
          flex items-center justify-center
          mb-4
        `}
      >
        {loading ? (
          <>
            <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
            Processing...
          </>
        ) : subscriptionStatus?.hasActiveSubscription ? (
          'Manage Subscription'
        ) : (
          'Subscribe to Pro Plan'
        )}
      </button>

      <div className="mt-6 text-sm text-gray-600">
        <h3 className="font-semibold mb-2">Test Information:</h3>
        <ul className="list-disc pl-5 space-y-1">
          <li>Using production billing API endpoint</li>
          <li>Pro plan subscription test</li>
          <li>Price: $99/month</li>
          <li>Test Card: 4242 4242 4242 4242</li>
          <li>Any future date for expiry, any 3 digits for CVC</li>
        </ul>
      </div>
    </div>
  );
};

export default BillingTest;