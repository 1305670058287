// src/components/landing/Stats.tsx
import React from 'react';
import type { Stat } from '../../types/landing';

/**
 * Statistics section component for the landing page.
 * Displays key metrics and achievements.
 *
 * @returns {JSX.Element} The rendered Stats component
 */
const Stats: React.FC = () => {
  const stats: Stat[] = [
    { value: "99.9%", label: "Uptime Guarantee" },
    { value: "10x", label: "Faster Deployment" },
    { value: "24/7", label: "Expert Support" }
  ];

  return (
    <div className="py-12 sm:py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center">
          {stats.map((stat, index) => (
            <div 
              key={index}
              className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <h3 className="text-4xl font-bold text-[#ed4c4c]">{stat.value}</h3>
              <p className="mt-2 text-gray-600">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;