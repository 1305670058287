import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import { useAuth } from '../contexts/AuthContext';
import { Check, AlertCircle} from 'lucide-react';

interface PricingTier {
  readonly name: string;
  readonly price: string;
  readonly priceId?: string;
  readonly description: string;
  readonly features: string[];
  readonly ctaText: string;
  readonly highlighted?: boolean;
  readonly isEnterprise?: boolean;
}

interface PricingState {
  readonly loading: string | null;
  readonly error: string | null;
}

const CheckLoading: React.FC = () => (
  <div className="flex justify-center items-center min-h-screen">
    <span className="loading loading-spinner loading-lg text-primary"></span>
  </div>
);

const Pricing: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);
  const [isChecking, setIsChecking] = useState(true);
  const [state, setState] = useState<PricingState>({
    loading: null,
    error: null,
  });

  const BILLING_API = import.meta.env.VITE_PROD_BILLING_API;

  const pricingTiers: readonly PricingTier[] = [
    {
      name: 'Starter',
      price: '$49',
      priceId: import.meta.env.VITE_STRIPE_STARTER_PRICE_ID,
      description: 'Perfect for small projects',
      features: [
        'Up to 5 devices',
        'Basic analytics',
        'Community support',
        '7-day data retention',
      ],
      ctaText: 'Starter Plan',
    },
    {
      name: 'Pro',
      price: '$99',
      priceId: import.meta.env.VITE_STRIPE_PRO_PRICE_ID,
      description: 'Best for growing teams',
      features: [
        'Up to 20 devices',
        'Advanced analytics',
        'Priority support',
        '30-day data retention',
        'Custom integrations',
      ],
      ctaText: 'Pro Plan',
      highlighted: true,
    },
    {
      name: 'Enterprise',
      price: 'Custom',
      description: 'For large organizations',
      features: [
        'Unlimited devices',
        'Custom analytics',
        '24/7 support',
        'Custom data retention',
        'Dedicated account manager',
      ],
      ctaText: 'Contact Sales',
      isEnterprise: true,
    },
  ];

  useEffect(() => {
    const checkStatus = async () => {
      if (user?.email) {
        try {
          const response = await fetch(
            `${BILLING_API}/api/subscriptions/status/${encodeURIComponent(user.email)}`,
            {
              credentials: 'include',
            }
          );
          const data = await response.json();
          console.log('Subscription status:', data);
          setSubscriptionStatus(data);
        } catch (err) {
          console.error('Status check error:', err);
        } finally {
          setIsChecking(false);
        }
      } else {
        setIsChecking(false);
      }
    };

    checkStatus();
  }, [user, BILLING_API]);

  const getButtonText = (tier: PricingTier) => {
    if (!user) return 'Sign in to Subscribe';
    if (tier.isEnterprise) return 'Contact Sales';
    
    if (subscriptionStatus?.hasActiveSubscription) {
      if (subscriptionStatus.subscriptionPlan === tier.priceId) {
        return 'Current Plan';
      }
      const currentPlanIndex = pricingTiers.findIndex(t => t.priceId === subscriptionStatus.subscriptionPlan);
      const thisPlanIndex = pricingTiers.findIndex(t => t.priceId === tier.priceId);
      
      if (currentPlanIndex === -1 || thisPlanIndex === -1) return 'Select Plan';
      return thisPlanIndex > currentPlanIndex ? 'Upgrade Plan' : 'Downgrade Plan';
    }
    
    return 'Start Free Trial';
  };

  const handleSubscribe = async (tier: PricingTier): Promise<void> => {
    setState((prev) => ({ ...prev, error: null }));

    if (tier.isEnterprise) {
      navigate('/contact');
      return;
    }

    if (!user) {
      navigate('/login', { state: { returnUrl: '/pricing' } });
      return;
    }

    try {
      setState((prev) => ({ ...prev, loading: tier.name }));

      let endpoint = subscriptionStatus?.hasActiveSubscription 
        ? 'create-portal-session'
        : 'create-checkout-session';

      const response = await fetch(
        `${BILLING_API}/api/subscriptions/${endpoint}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            endpoint === 'create-portal-session' 
              ? {
                  userId: user.id,
                  email: user.email,
                }
              : {
                  priceId: tier.priceId,
                  userId: user.id,
                  customerEmail: user.email,
                }
          ),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to process request');
      }

      const data = await response.json();
      console.log('API Response:', data);

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Subscription error:', err);
      setState((prev) => ({
        ...prev,
        error: err instanceof Error ? err.message : 'An unexpected error occurred',
        loading: null,
      }));
    }
  };

  if (isChecking) {
    return <CheckLoading />;
  }

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">
            Pricing Plans
          </h1>
          <p className="text-xl text-base-content/70">
            Choose the perfect plan for your needs
          </p>

          {state.error && (
            <div className="alert alert-error mt-4">
              <AlertCircle className="w-5 h-5" />
              <span>{state.error}</span>
            </div>
          )}

          {user && subscriptionStatus?.userPlanName && (
            <div className="mt-6 p-4 bg-base-200 rounded-lg inline-block">
              <p className="text-base-content">
                Your current plan:{' '}
                <strong className="text-primary">
                  {subscriptionStatus.userPlanName}
                </strong>{' '}
                {subscriptionStatus.hasActiveSubscription && (
                  <span className="badge badge-success gap-1">
                    <Check className="w-4 h-4" />
                    Active
                  </span>
                )}
              </p>
              {subscriptionStatus.hasActiveSubscription && (
                <p className="text-sm text-base-content/70 mt-1">
                  You can compare plans or manage your subscription below
                </p>
              )}
            </div>
          )}
        </div>

        <div className="grid md:grid-cols-3 gap-8 mt-16">
          {pricingTiers.map((tier) => (
            <div
              key={tier.name}
              className={`card bg-base-100 ${
                subscriptionStatus?.subscriptionPlan === tier.priceId
                  ? 'border-2 border-success shadow-lg'
                  : tier.highlighted
                  ? 'border-2 border-primary shadow-lg'
                  : 'border border-base-300'
              }`}
            >
              <div className="card-body">
                {user && subscriptionStatus?.subscriptionPlan === tier.priceId && (
                  <div className="badge badge-success">Current Plan</div>
                )}
                
                <h3 className="text-2xl font-bold">{tier.name}</h3>
                <p className="text-base-content/70">{tier.description}</p>
                
                <div className="my-4">
                  <span className="text-4xl font-bold">
                    {tier.price}
                  </span>
                  {tier.price !== 'Custom' && (
                    <span className="text-base-content/70">/month</span>
                  )}
                </div>

                <ul className="space-y-4">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-center gap-2">
                      <Check className="text-success flex-shrink-0" />
                      <span className="text-base-content/70">{feature}</span>
                    </li>
                  ))}
                </ul>

                <div className="card-actions justify-end mt-8">
                  <button
                    onClick={() => handleSubscribe(tier)}
                    disabled={state.loading === tier.name}
                    className={`btn btn-block ${
                      tier.highlighted ? 'btn-primary' : 'btn-neutral'
                    }`}
                  >
                    {state.loading === tier.name ? (
                      <>
                        <span className="loading loading-spinner"></span>
                        Processing...
                      </>
                    ) : (
                      getButtonText(tier)
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Pricing;