// src/components/landing/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Github,
  MessageSquare,
  Twitter,
  Instagram,
  Youtube
} from 'lucide-react';

interface FooterProps {
  icon: string;
}

/**
 * Footer component for the landing page.
 * Displays social links, navigation links, and copyright notice.
 *
 * @param {FooterProps} props - The props for the Footer component
 * @returns {JSX.Element} The rendered Footer component
 */
const Footer: React.FC<FooterProps> = ({ icon }) => {
  const socialLinks = [
    {
      href: "https://github.com/videobase-cloud",
      label: "GitHub",
      icon: <Github className="w-6 h-6" />
    },
    {
      href: "https://discord.com/invite/3RMYzyR9",
      label: "Discord",
      icon: <MessageSquare className="w-6 h-6" />
    },
    {
      href: "https://x.com/videobasecloud",
      label: "X (formerly Twitter)",
      icon: <Twitter className="w-6 h-6" />
    },
    {
      href: "https://www.instagram.com/videobase.cloud/",
      label: "Instagram",
      icon: <Instagram className="w-6 h-6" />
    },
    {
      href: "https://www.youtube.com/@VideobaseCloud",
      label: "YouTube",
      icon: <Youtube className="w-6 h-6" />
    }
  ];

  return (
    <footer className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex items-center justify-center space-x-2 mb-4">
            <img src={icon} alt="VideoBase Icon" className="h-8 w-8" />
            <h3 className="text-xl font-bold text-black">VideoBase</h3>
          </div>
          
          <p className="text-gray-600">Empowering IoT Vision Intelligence</p>
          
          <div className="mt-8 mb-8">
            <div className="flex justify-center space-x-6">
              {socialLinks.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-black transition-colors duration-200"
                  aria-label={link.label}
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>

          <div className="flex justify-center space-x-6">
            <Link 
              to="/privacy" 
              className="text-gray-600 hover:text-black transition-colors duration-200"
            >
              Privacy
            </Link>
            <Link 
              to="/terms" 
              className="text-gray-600 hover:text-black transition-colors duration-200"
            >
              Terms
            </Link>
            <Link 
              to="/contact" 
              className="text-gray-600 hover:text-black transition-colors duration-200"
            >
              Contact
            </Link>
          </div>

          <div className="mt-8 text-gray-500 text-sm">
            © {new Date().getFullYear()} VideoBase. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;