// src/components/landing/Partners.tsx
import React from 'react';

// Import all assets directly
import seeedLogo from '../../assets/seeed.png';
import nvidiaLogo from '../../assets/nvidia.svg';
import agriweissLogo from '../../assets/agriweiss.svg';
import arduinoLogo from '../../assets/arduino.png';
import anthropicLogo from '../../assets/anthropic.svg';
import openaiLogo from '../../assets/openai.svg';
import stabilityaiLogo from '../../assets/stabilityai.svg';
import heysaladLogo from '../../assets/heysalad.svg';
import pytorchLogo from '../../assets/pytorch.svg';
import tensorflowLogo from '../../assets/tensorflowlite.png';
import onnxLogo from '../../assets/onnx.svg';
import caffeLogo from '../../assets/caffe.svg';
import awsLogo from '../../assets/aws.svg';
import gcpLogo from '../../assets/googlecloud.svg';
import azureLogo from '../../assets/msazure.svg';
import supabaseLogo from '../../assets/supabase.svg';

/**
 * Interface for partner information following Google TypeScript style guide.
 */
interface Partner {
  readonly name: string;
  readonly logo: string;
  readonly alt: string;
  readonly url: string;
  readonly category: string;
}

/**
 * Partners section component that displays technology partner logos.
 * Organizes logos by category with consistent spacing.
 *
 * @returns {JSX.Element} The rendered Partners component
 */
const Partners: React.FC = () => {
  const partners: readonly Partner[] = [
    // Hardware Partners
    {
      name: "Seeed Studio",
      logo: seeedLogo,
      alt: "Seeed Studio Logo",
      url: "https://www.seeedstudio.com",
      category: "HARDWARE"
    },
    {
      name: "NVIDIA",
      logo: nvidiaLogo,
      alt: "NVIDIA Logo",
      url: "https://www.nvidia.com",
      category: "HARDWARE"
    },
    {
      name: "Agriweiss",
      logo: agriweissLogo,
      alt: "Agriweiss Logo",
      url: "https://www.agriweiss.com",
      category: "HARDWARE"
    },
    {
      name: "Arduino",
      logo: arduinoLogo,
      alt: "Arduino Logo",
      url: "https://www.arduino.cc",
      category: "HARDWARE"
    },
    
    // AI Partners
    {
      name: "Anthropic",
      logo: anthropicLogo,
      alt: "Anthropic Logo",
      url: "https://www.anthropic.com",
      category: "AI"
    },
    {
      name: "OpenAI",
      logo: openaiLogo,
      alt: "OpenAI Logo",
      url: "https://openai.com",
      category: "AI"
    },
    {
      name: "Stability AI",
      logo: stabilityaiLogo,
      alt: "Stability AI Logo",
      url: "https://stability.ai",
      category: "AI"
    },
    {
      name: "Hey Salad",
      logo: heysaladLogo,
      alt: "Hey Salad Logo",
      url: "https://heysalad.app",
      category: "AI"
    },
    
    // ML Frameworks
    {
      name: "PyTorch",
      logo: pytorchLogo,
      alt: "PyTorch Logo",
      url: "https://pytorch.org",
      category: "FRAMEWORKS"
    },
    {
      name: "TensorFlow Lite",
      logo: tensorflowLogo,
      alt: "TensorFlow Lite Logo",
      url: "https://www.tensorflow.org/lite",
      category: "FRAMEWORKS"
    },
    {
      name: "ONNX",
      logo: onnxLogo,
      alt: "ONNX Logo",
      url: "https://onnx.ai",
      category: "FRAMEWORKS"
    },
    {
      name: "Caffe",
      logo: caffeLogo,
      alt: "Caffe Logo",
      url: "https://caffe.berkeleyvision.org",
      category: "FRAMEWORKS"
    },
    
    // Cloud Partners
    {
      name: "AWS",
      logo: awsLogo,
      alt: "AWS Logo",
      url: "https://aws.amazon.com",
      category: "CLOUD"
    },
    {
      name: "Google Cloud",
      logo: gcpLogo,
      alt: "Google Cloud Logo",
      url: "https://cloud.google.com",
      category: "CLOUD"
    },
    {
      name: "Microsoft Azure",
      logo: azureLogo,
      alt: "Microsoft Azure Logo",
      url: "https://azure.microsoft.com",
      category: "CLOUD"
    },
    {
      name: "Supabase",
      logo: supabaseLogo,
      alt: "Supabase Logo",
      url: "https://supabase.com",
      category: "CLOUD"
    }
  ];

  /**
   * Filter partners by category.
   * 
   * @param {Partner} partner - The partner to check
   * @param {string} category - The category to filter by
   * @returns {boolean} Whether the partner matches the category
   */
  const filterByCategory = (partner: Partner, category: string): boolean => {
    return partner.category === category;
  };

  return (
    <div className="py-8 sm:py-12 bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-16">
          <h2 className="text-2xl sm:text-3xl font-bold text-[#ed4c4c]">
            Powered by Industry Leaders
          </h2>
          <p className="mt-2 sm:mt-4 text-sm sm:text-base text-gray-600 max-w-2xl mx-auto">
            We leverage cutting-edge technologies and partner with industry leaders 
            to deliver exceptional IoT vision solutions.
          </p>
        </div>

        <div className="space-y-8 sm:space-y-16">
          {/* Hardware Partners - Mobile Optimized */}
          <div className="grid grid-cols-2 sm:flex sm:justify-center items-center gap-6 sm:gap-24">
            {partners.filter((p: Partner) => filterByCategory(p, 'HARDWARE')).map((partner: Partner) => (
              <a
                key={partner.name}
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center hover:opacity-80 transition-opacity"
              >
                <div className="w-32 sm:w-48 h-12 sm:h-20 flex items-center justify-center">
                  <img
                    src={partner.logo}
                    alt={partner.alt}
                    className="max-w-full max-h-full object-contain"
                    loading="lazy"
                  />
                </div>
              </a>
            ))}
          </div>

          {/* AI Partners - Mobile Optimized */}
          <div className="grid grid-cols-2 sm:flex sm:justify-center items-center gap-6 sm:gap-24">
            {partners.filter((p: Partner) => filterByCategory(p, 'AI')).map((partner: Partner) => (
              <a
                key={partner.name}
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center hover:opacity-80 transition-opacity"
              >
                <div className="w-32 sm:w-48 h-10 sm:h-16 flex items-center justify-center">
                  <img
                    src={partner.logo}
                    alt={partner.alt}
                    className="max-w-full max-h-full object-contain"
                    loading="lazy"
                  />
                </div>
              </a>
            ))}
          </div>

          {/* ML Framework Partners - Mobile Optimized */}
          <div className="grid grid-cols-2 sm:flex sm:justify-center items-center gap-6 sm:gap-24">
            {partners.filter((p: Partner) => filterByCategory(p, 'FRAMEWORKS')).map((partner: Partner) => (
              <a
                key={partner.name}
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center hover:opacity-80 transition-opacity"
              >
                <div className="w-32 sm:w-48 h-10 sm:h-16 flex items-center justify-center">
                  <img
                    src={partner.logo}
                    alt={partner.alt}
                    className="max-w-full max-h-full object-contain"
                    loading="lazy"
                  />
                </div>
              </a>
            ))}
          </div>

          {/* Cloud Partners - Mobile Optimized */}
          <div className="grid grid-cols-2 sm:flex sm:justify-center items-center gap-6 sm:gap-24">
            {partners.filter((p: Partner) => filterByCategory(p, 'CLOUD')).map((partner: Partner) => (
              <a
                key={partner.name}
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center hover:opacity-80 transition-opacity"
              >
                <div className="w-32 sm:w-48 h-10 sm:h-16 flex items-center justify-center">
                  <img
                    src={partner.logo}
                    alt={partner.alt}
                    className="max-w-full max-h-full object-contain"
                    loading="lazy"
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;