// src/App.tsx
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SubscriptionProvider, useSubscription } from './contexts/SubscriptionContext';

// Layouts
import DashboardLayout from './layouts/DasboardLayout';

// Marketing Pages
import Landing from './pages/Landing';
import About from './pages/About';
import Blog from './pages/Blog';
import Docs from './pages/Docs';
import Pricing from './pages/Pricing';
import BillingTest from './pages/BillingTest';

// Dashboard Pages
import Dashboard from './pages/Dashboard';
import DeviceInfo from './pages/Devices/DeviceInfo';
import Devices from './pages/Devices/Devices';
import Viewer from './pages/Viewer';
import Stats from './pages/Stats';
import Settings from './pages/Settings';

// Auth Components
import { LoginForm } from './components/auth/LoginForm';
import { SignUpForm } from './components/auth/SignUpForm';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const LoadingSpinner: React.FC = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary" />
  </div>
);

const ProtectedRouteComponent: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user, loading: authLoading } = useAuth();
  const { hasActiveSubscription, isLoading: subscriptionLoading } = useSubscription();

  if (authLoading || subscriptionLoading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!hasActiveSubscription) {
    return <Navigate to="/pricing" replace />;
  }

  return <>{children}</>;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => (
  <ProtectedRouteComponent>{children}</ProtectedRouteComponent>
);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <Router>
          <Routes>
            {/* Public Marketing Routes */}
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/billing-test" element={<BillingTest />} />

            {/* Auth Routes */}
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignUpForm />} />
            
            {/* Protected Dashboard Routes */}
            <Route
              element={
                <ProtectedRoute>
                  <DashboardLayout />
                </ProtectedRoute>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/devices" element={<Devices />} />
              <Route path="/devices/:deviceId" element={<DeviceInfo />} />
              <Route path="/viewer" element={<Viewer />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/settings" element={<Settings />} />
            </Route>

            {/* Fallback Routes */}
            <Route 
              path="/dashboard/*" 
              element={<Navigate to="/dashboard" replace />} 
            />
            <Route 
              path="*" 
              element={<Navigate to="/" replace />} 
            />
          </Routes>
        </Router>
      </SubscriptionProvider>
    </AuthProvider>
  );
};

export default App;