// src/components/landing/Navigation.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, User, LogOut, Settings, LayoutDashboard } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscription } from '../../contexts/SubscriptionContext';

interface NavigationProps {
  icon: string;
}

interface NavItem {
  name: string;
  href: string;
  isExternal?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ icon }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { hasActiveSubscription } = useSubscription();
  const userMenuRef = useRef<HTMLDivElement>(null);

  const navItems: readonly NavItem[] = [
    { name: 'Home', href: '/' },
    { name: 'Docs', href: '/docs' },
    { name: 'Blog', href: '/blog' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'About', href: '/about' }
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleGetStarted = (): void => {
    if (user) {
      navigate(hasActiveSubscription ? '/dashboard' : '/pricing');
    } else {
      navigate('/signup', {
        state: {
          intent: 'subscription',
          returnUrl: '/pricing'
        }
      });
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
      setIsUserMenuOpen(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleNavigation = (href: string): void => {
    setIsMobileMenuOpen(false);
    setIsUserMenuOpen(false);
    navigate(href);
  };

  return (
    <nav className="bg-white border-b sticky top-0 z-40 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo Section */}
          <div className="flex items-center space-x-2 hover:opacity-80 transition-all cursor-pointer"
               onClick={() => navigate('/')}>
            <img src={icon} alt="VideoBase Icon" className="h-8 w-8" />
            <h1 className="text-2xl font-bold text-black">VideoBase</h1>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {/* Navigation Links */}
            <div className="flex space-x-8">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm font-medium transition-all 
                           hover:bg-gray-100 rounded-md relative group"
                >
                  {item.name}
                  <span className="absolute inset-x-0 bottom-0 h-0.5 bg-[#ed4c4c] transform scale-x-0 
                                 group-hover:scale-x-100 transition-transform origin-left"></span>
                </Link>
              ))}
            </div>

            {/* Authentication/User Section */}
            <div className="flex items-center space-x-4">
              {user ? (
                <div className="relative" ref={userMenuRef}>
                  <button
                    onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                    className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 
                             px-3 py-2 text-sm font-medium transition-all hover:bg-gray-100 
                             rounded-full focus:outline-none"
                  >
                    <div className="w-8 h-8 rounded-full bg-[#ed4c4c] flex items-center justify-center text-white">
                      {user.email?.charAt(0).toUpperCase() || <User size={20} />}
                    </div>
                  </button>

                  {/* User Dropdown Menu */}
                  {isUserMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                      <div className="px-4 py-2 text-sm text-gray-700 border-b">
                        {user.email}
                      </div>
                      <Link
                        to="/dashboard"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsUserMenuOpen(false)}
                      >
                        <LayoutDashboard className="mr-2 h-4 w-4" />
                        Dashboard
                      </Link>
                      <Link
                        to="/settings"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsUserMenuOpen(false)}
                      >
                        <Settings className="mr-2 h-4 w-4" />
                        Settings
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <Link 
                  to="/login" 
                  className="text-gray-600 hover:text-gray-900 px-3 py-2 text-sm font-medium 
                           transition-all hover:bg-gray-100 rounded-md"
                >
                  Sign in
                </Link>
              )}
              {!user && (
                <button 
                  onClick={handleGetStarted}
                  className="bg-[#ed4c4c] text-white px-4 py-2 rounded-lg text-sm font-medium 
                           transition-all hover:bg-[#c43c3c] transform hover:scale-105 
                           hover:shadow-md active:scale-95"
                >
                  Get Started
                </button>
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 
                       hover:text-gray-500 hover:bg-gray-100 transition-all focus:outline-none"
              aria-expanded="false"
              aria-label="Toggle menu"
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div 
        className={`md:hidden transition-all duration-300 ease-in-out ${
          isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
        }`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 block px-3 py-2 
                       rounded-md text-base font-medium transition-all"
              onClick={() => handleNavigation(item.href)}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          {user ? (
            <div className="space-y-1">
              <div className="px-4 py-2 text-sm text-gray-700 border-b">
                {user.email}
              </div>
              <Link
                to="/dashboard"
                className="flex items-center px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <LayoutDashboard className="mr-2 h-5 w-5" />
                Dashboard
              </Link>
              <Link
                to="/settings"
                className="flex items-center px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <Settings className="mr-2 h-5 w-5" />
                Settings
              </Link>
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
              >
                <LogOut className="mr-2 h-5 w-5" />
                Logout
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center space-x-4 px-4">
              <Link 
                to="/login" 
                className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 px-3 py-2 
                         rounded-md text-base font-medium transition-all"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Sign in
              </Link>
              <button 
                onClick={handleGetStarted}
                className="bg-[#ed4c4c] text-white px-4 py-2 rounded-lg text-base font-medium 
                         transition-all hover:bg-[#c43c3c] transform hover:scale-105 
                         hover:shadow-md active:scale-95"
              >
                Get Started
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;