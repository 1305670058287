import React, { useState, useEffect } from 'react';
import { PlusCircle } from 'lucide-react';
import { Device, DeviceType } from '../../types/device';
import DeviceCard from '../../components/devices/DeviceCard';
import { DeviceForm } from '../../components/devices/DeviceForm';
import { deviceService } from '../../services/deviceService';

/**
 * Interface for device form data when creating a new device.
 */
interface DeviceFormData {
  /** Name of the device */
  name: string;
  /** Type of the device */
  deviceType: DeviceType;
}

/**
 * Devices page component for managing and displaying connected devices.
 * Provides functionality for CRUD operations on devices.
 */
const Devices: React.FC = () => {
  // State management
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);

  /**
   * Loads devices from the service when component mounts.
   */
  useEffect(() => {
    loadDevices();
  }, []);

  /**
   * Fetches all devices from the service.
   * Updates loading and error states appropriately.
   */
  const loadDevices = async (): Promise<void> => {
    try {
      setLoading(true);
      setError(null);
      const data = await deviceService.getDevices();
      setDevices(data);
    } catch (err) {
      setError('Failed to load devices');
      console.error('Error loading devices:', err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Updates a device's information.
   * 
   * @param deviceId - The ID of the device to update
   * @param updates - Partial device object containing the updates
   */
  const handleDeviceUpdate = async (deviceId: string, updates: Partial<Device>): Promise<void> => {
    try {
      setError(null);
      await deviceService.updateDevice(deviceId, updates);
      await loadDevices();
    } catch (err) {
      console.error('Error updating device:', err);
      setError('Failed to update device');
    }
  };

  /**
   * Deletes a device.
   * 
   * @param deviceId - The ID of the device to delete
   */
  const handleDeviceDelete = async (deviceId: string): Promise<void> => {
    try {
      setError(null);
      await deviceService.deleteDevice(deviceId);
      setDevices(devices.filter(device => device.id !== deviceId));
    } catch (err) {
      console.error('Error deleting device:', err);
      setError('Failed to delete device');
    }
  };

  /**
   * Creates a new device.
   * 
   * @param formData - Form data containing device information
   */
  const handleCreateDevice = async (formData: DeviceFormData): Promise<void> => {
    try {
      setError(null);
      const newDevice = await deviceService.createDevice(formData.name, formData.deviceType);
      setDevices(prevDevices => [newDevice, ...prevDevices]);
      setShowForm(false);
    } catch (err) {
      console.error('Error creating device:', err);
      setError('Failed to create device');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <div className="flex flex-col items-center gap-4">
          <span className="loading loading-spinner loading-lg"></span>
          <p className="text-base-content/70">Loading your devices...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen p-4 flex justify-center items-center">
        <div className="alert alert-error max-w-md">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="stroke-current shrink-0 h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
          <div>
            <h3 className="font-bold">Error</h3>
            <div className="text-sm">{error}</div>
          </div>
          <button 
            className="btn btn-sm" 
            onClick={loadDevices}
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 bg-base-200">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold">Your Devices</h1>
            <p className="text-base-content/70">
              Manage and monitor your connected devices
            </p>
          </div>
          <button 
            onClick={() => setShowForm(true)} 
            className="btn btn-primary"
          >
            <PlusCircle className="w-4 h-4 mr-2" />
            Add Device
          </button>
        </div>

        {/* Device Form */}
        {showForm && (
          <DeviceForm
            onSubmit={handleCreateDevice}
            onCancel={() => setShowForm(false)}
          />
        )}

        {/* Device List */}
        {devices.length === 0 && !showForm ? (
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body items-center text-center">
              <h2 className="card-title">No Devices Found</h2>
              <p className="text-base-content/70">
                Get started by adding your first device
              </p>
              <div className="card-actions justify-center mt-4">
                <button 
                  className="btn btn-primary" 
                  onClick={() => setShowForm(true)}
                >
                  <PlusCircle className="w-4 h-4 mr-2" />
                  Add Your First Device
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {devices.map(device => (
              <DeviceCard
                key={device.id}
                device={device}
                onUpdate={(updates) => handleDeviceUpdate(device.id, updates)}
                onDelete={() => handleDeviceDelete(device.id)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Devices;