// src/components/landing/Features.tsx
import React from 'react';
import { Video, Cloud, Bot } from 'lucide-react';
import type { Feature } from '../../types/landing';

/**
 * Features section component for the landing page.
 * Displays key product features in a grid layout.
 *
 * @returns {JSX.Element} The rendered Features component
 */
const Features: React.FC = () => {
  const features: Feature[] = [
    {
      icon: <Video className="w-12 h-12 text-[#ed4c4c]" />,
      title: "Easy Device Management",
      description: "Provision and manage video streaming devices with just a few clicks. Seamless deployment across your entire fleet."
    },
    {
      icon: <Cloud className="w-12 h-12 text-[#ed4c4c]" />,
      title: "Smart Storage",
      description: "Efficiently store and organize your video and image data with intelligent cloud management and automatic backups."
    },
    {
      icon: <Bot className="w-12 h-12 text-[#ed4c4c]" />,
      title: "Vision AI Tools",
      description: "Evaluate and deploy Vision AI models to your remote IoT devices. Real-time analytics and insights at your fingertips."
    }
  ];

  return (
    <div className="py-12 sm:py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-[#ed4c4c]">Powerful Features</h2>
        </div>
        <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-3">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="card bg-white shadow-xl hover:shadow-2xl transition-all hover:-translate-y-2"
            >
              <div className="card-body items-center text-center p-6">
                {feature.icon}
                <h3 className="card-title mt-4 text-[#ed4c4c]">{feature.title}</h3>
                <p className="text-gray-700">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;