import React, { createContext, useContext, useEffect, useState } from 'react';
import { authService, AuthUser } from '../services/authService';
import { supabase } from '../lib/supabaseClient';
import type { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { SignUpFormData } from '../types/auth';

interface StripeSubscription {
  id: string;
  user_id: string;
  status: 'active' | 'inactive' | 'canceled' | 'trial';
  price_id: string;
  current_period_start: string;
  current_period_end: string;
  customer_id: string;
}

type StripeSubscriptionPayload = RealtimePostgresChangesPayload<StripeSubscription>;

interface Subscription {
  status: 'active' | 'inactive' | 'canceled' | 'trial';
  priceId: string | undefined;
  currentPeriodEnd: string | undefined;
}

export interface AuthUserWithSubscription extends AuthUser {
  subscription: Subscription;
}

interface AuthContextType {
  user: AuthUserWithSubscription | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, userData: SignUpFormData) => Promise<void>;
  signOut: () => Promise<void>;
  checkSubscription: () => Promise<void>;
}

const defaultSubscription: Subscription = {
  status: 'inactive',
  priceId: undefined,
  currentPeriodEnd: undefined
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<AuthUserWithSubscription | null>(null);
  const [loading, setLoading] = useState(true);

  const updateUserSubscription = (subscriptionData: StripeSubscription | null) => {
    setUser(currentUser => {
      if (!currentUser) return null;
      return {
        ...currentUser,
        subscription: subscriptionData ? {
          status: subscriptionData.status || 'inactive',
          priceId: subscriptionData.price_id,
          currentPeriodEnd: subscriptionData.current_period_end
        } : defaultSubscription
      };
    });
  };

  const checkSubscription = async () => {
    if (!user?.id) return;

    try {
      // First check realtime schema
      const { data: realtimeSubscription, error: realtimeError } = await supabase
        .from('realtime.videobase_stripe_customer_subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (!realtimeError && realtimeSubscription) {
        updateUserSubscription(realtimeSubscription);
        return;
      }

      // If realtime schema fails or returns no data, check public schema
      const { data: publicSubscription, error: publicError } = await supabase
        .from('videobase_stripe_customer_subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (!publicError && publicSubscription) {
        updateUserSubscription(publicSubscription);
        return;
      }

      // If no subscription found in either schema, set default
      updateUserSubscription(null);

    } catch (error) {
      console.error('Error checking subscription:', error);
      updateUserSubscription(null);
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      try {
        setLoading(true);
        const currentUser = await authService.getCurrentUser();
        
        if (currentUser) {
          setUser({
            ...currentUser,
            subscription: defaultSubscription
          });
          await checkSubscription();
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    // Subscribe to auth changes
    const { data: { subscription } } = authService.onAuthStateChange(async (authUser) => {
      if (authUser) {
        setUser({
          ...authUser,
          subscription: defaultSubscription
        });
        await checkSubscription();
      } else {
        setUser(null);
      }
    });

    // Subscribe to changes in both schemas
    const realtimeChannel = supabase
      .channel('realtime-subscriptions')
      .on<StripeSubscription>(
        'postgres_changes',
        {
          event: '*',
          schema: 'realtime',
          table: 'videobase_stripe_customer_subscriptions'
        },
        (payload: StripeSubscriptionPayload) => {
          if (payload.new && 'user_id' in payload.new && user?.id === payload.new.user_id) {
            checkSubscription();
          }
        }
      )
      .subscribe();

    const publicChannel = supabase
      .channel('public-subscriptions')
      .on<StripeSubscription>(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'videobase_stripe_customer_subscriptions'
        },
        (payload: StripeSubscriptionPayload) => {
          if (payload.new && 'user_id' in payload.new && user?.id === payload.new.user_id) {
            checkSubscription();
          }
        }
      )
      .subscribe();

    initAuth();

    return () => {
      subscription.unsubscribe();
      realtimeChannel.unsubscribe();
      publicChannel.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await authService.signIn(email, password);
      if (response.user) {
        setUser({
          ...response.user,
          subscription: defaultSubscription
        });
        await checkSubscription();
      }
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const signUp = async (email: string, password: string, userData: SignUpFormData) => {
    try {
      const response = await authService.signUp(email, password, userData);
      if (response.user) {
        setUser({
          ...response.user,
          subscription: defaultSubscription
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      throw error;
    }
  };

  const signOut = async () => {
    await authService.signOut();
    setUser(null);
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        loading, 
        signIn, 
        signUp, 
        signOut,
        checkSubscription 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const useSubscriptionStatus = () => {
  const { user, loading } = useAuth();
  
  return {
    hasActiveSubscription: user?.subscription?.status === 'active',
    subscriptionPlan: user?.subscription?.priceId,
    subscriptionEnds: user?.subscription?.currentPeriodEnd,
    isLoading: loading
  };
};