import { supabase } from '../lib/supabaseClient';
import { User } from '@supabase/supabase-js';
import { SignUpFormData } from '../types/auth';

export type AuthUser = User;

interface UserProfile {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  address: string;
  location: string;
  profession: string;
  number_of_devices: number;
  project_name: string;
  referral_code: string;
  use_type: string;
  created_at?: string;
  updated_at?: string;
}

export const authService = {
    async signUp(email: string, password: string, userData: SignUpFormData) {
        // First create the auth user
        const { data: authData, error: authError } = await supabase.auth.signUp({
            email,
            password,
            options: {
                data: {
                    first_name: userData.firstName,
                    last_name: userData.lastName,
                }
            }
        });
        
        if (authError) {
            console.error('Auth error during signup:', authError);
            throw authError;
        }

        if (!authData.user) {
            throw new Error('No user data returned from signup');
        }

        // Then create the profile
        const { error: profileError } = await supabase
            .from('profiles')
            .insert({
                id: authData.user.id,
                first_name: userData.firstName,
                last_name: userData.lastName,
                phone_number: userData.phoneNumber,
                address: userData.address,
                location: userData.location,
                profession: userData.profession,
                number_of_devices: userData.numberOfDevices,
                project_name: userData.projectName,
                referral_code: userData.referralCode,
                use_type: userData.useType,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString()
            } as UserProfile);

        if (profileError) {
            console.error('Profile creation error:', profileError);
            // If profile creation fails, attempt to clean up the auth user
            try {
                await supabase.auth.admin.deleteUser(authData.user.id);
            } catch (deleteError) {
                console.error('Failed to delete auth user after profile creation failed:', deleteError);
            }
            throw profileError;
        }

        return authData;
    },

    async signIn(email: string, password: string) {
        const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        });
        if (error) {
            console.error('Sign in error:', error);
            throw error;
        }
        return data;
    },

    async signOut() {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error('Sign out error:', error);
            throw error;
        }
    },

    async getCurrentUser(): Promise<User | null> {
        try {
            const { data: { user }, error } = await supabase.auth.getUser();
            if (error) throw error;
            return user;
        } catch (error) {
            console.error('Error getting current user:', error);
            return null;
        }
    },

    async getUserProfile(userId: string): Promise<UserProfile | null> {
        try {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', userId)
                .single();

            if (error) throw error;
            return data;
        } catch (error) {
            console.error('Error getting user profile:', error);
            return null;
        }
    },

    async updateUserProfile(userId: string, updates: Partial<UserProfile>) {
        const { error } = await supabase
            .from('profiles')
            .update({
                ...updates,
                updated_at: new Date().toISOString()
            })
            .eq('id', userId);

        if (error) {
            console.error('Error updating user profile:', error);
            throw error;
        }
    },

    onAuthStateChange(callback: (user: User | null) => void) {
        return supabase.auth.onAuthStateChange((_event, session) => {
            callback(session?.user ?? null);
        });
    }
};