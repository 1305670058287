// src/components/auth/SignUpForm.tsx

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { AlertCircle } from 'lucide-react';
import { SignUpFormData } from './types/auth.types';
import icon from '../../assets/icon.svg';

export const SignUpForm: React.FC = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    address: '',
    location: '',
    profession: '',
    numberOfDevices: 1,
    projectName: '',
    referralCode: '',
    useType: ''
  });

  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const BILLING_API = import.meta.env.VITE_PROD_BILLING_API;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const checkSubscriptionAndRedirect = async (email: string) => {
    try {
      const response = await fetch(
        `${BILLING_API}/api/subscriptions/status/${encodeURIComponent(email)}`,
        {
          credentials: 'include',
        }
      );

      const data = await response.json();
      
      if (data.hasActiveSubscription) {
        navigate('/dashboard', { replace: true });
      } else {
        navigate('/pricing', { replace: true });
      }
    } catch (err) {
      console.error('Subscription check error:', err);
      navigate('/pricing', { replace: true });
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      
      await signUp(formData.email, formData.password, formData);
      await checkSubscriptionAndRedirect(formData.email);
    } catch (err) {
      console.error('Signup error:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Failed to create account');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-base-200 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="card bg-white shadow-xl">
          <div className="card-body">
            {/* Logo and Header */}
            <div className="flex flex-col items-center mb-8">
            <Link 
              to="/" 
              className="flex items-center justify-center mb-4 hover:opacity-80 transition-opacity"
              replace={true}
            >
              <img src={icon} alt="Videobase Logo" className="w-8 h-8 mr-2" />
              <h1 className="text-2xl font-bold text-black">Videobase</h1>
            </Link>
              <h2 className="text-2xl font-bold text-center">Create your account</h2>
              <p className="text-gray-600 text-center mt-2">
                Join VideoBase to start managing your video devices
              </p>
            </div>

            {/* Error Display */}
            {error && (
              <div className="alert alert-error mb-6">
                <AlertCircle className="w-5 h-5" />
                <span>{error}</span>
              </div>
            )}

            {/* Sign Up Form */}
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Personal Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Personal Information</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">First Name</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Last Name</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Contact Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Contact Information</h3>
                <div className="space-y-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Email</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Password</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                      minLength={6}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Phone Number</span>
                    </label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Professional Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Professional Information</h3>
                <div className="space-y-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Profession</span>
                    </label>
                    <input
                      type="text"
                      name="profession"
                      value={formData.profession}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Number of Devices</span>
                    </label>
                    <input
                      type="number"
                      name="numberOfDevices"
                      value={formData.numberOfDevices}
                      onChange={handleChange}
                      className="input input-bordered"
                      min={1}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Project Information */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Project Information</h3>
                <div className="space-y-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Project Name</span>
                    </label>
                    <input
                      type="text"
                      name="projectName"
                      value={formData.projectName}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Use Type</span>
                    </label>
                    <select
                      name="useType"
                      value={formData.useType}
                      onChange={handleChange}
                      className="select select-bordered"
                      required
                    >
                      <option value="">Select Use Type</option>
                      <option value="personal">Personal</option>
                      <option value="business">Business</option>
                      <option value="education">Education</option>
                      <option value="research">Research</option>
                    </select>
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Referral Code (Optional)</span>
                    </label>
                    <input
                      type="text"
                      name="referralCode"
                      value={formData.referralCode}
                      onChange={handleChange}
                      className="input input-bordered"
                    />
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="space-y-4">
                <button
                  type="submit"
                  className="btn btn-primary w-full"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="loading loading-spinner"></span>
                  ) : (
                    'Create Account'
                  )}
                </button>

                <p className="text-center text-sm">
                  Already have an account?{' '}
                  <Link to="/login" className="text-primary hover:underline">
                    Sign in
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;