// src/components/landing/Devices.tsx
import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Device } from '../../types/landing';

interface DevicesProps {
  recameraImg: string;
  groveAiImg: string;
  sx1262Img: string;
  agriweissE5Img: string;
  esp32c3Img: string;
  esp32cSim800lImg: string;
}

const Devices: React.FC<DevicesProps> = ({
  recameraImg,
  groveAiImg,
  sx1262Img,
  agriweissE5Img,
  esp32c3Img,
  esp32cSim800lImg
}) => {
  const devices: Device[] = [
    {
      image: recameraImg,
      title: "Seeedstudio reCamera",
      description: "A powerful IoT camera platform designed for edge computing and AI applications. Perfect for real-time video analytics and processing.",
      learnMoreUrl: "https://www.seeedstudio.com/reCamera.html",
      altText: "Seeedstudio reCamera"
    },
    {
      image: groveAiImg,
      title: "Seeedstudio Grove AI v2",
      description: "An advanced AI vision module that excels in real-time image processing and analysis. Built for sophisticated computer vision applications.",
      learnMoreUrl: "https://www.seeedstudio.com/Grove-Vision-AI-Module-p-5457.html",
      altText: "Seeedstudio Grove AI"
    },
    {
      image: sx1262Img,
      title: "XIAO ESP32S3 LoRa Kit",
      description: "Complete vision kit featuring XIAO ESP32S3, Wio-SX1262 for Meshtastic & LoRa capabilities, integrated with Grove Vision AI V2 and Raspberry Pi OV5647 Camera Module.",
      learnMoreUrl: "https://www.seeedstudio.com/",
      altText: "XIAO ESP32S3 LoRa Kit"
    },
    {
      image: agriweissE5Img,
      title: "AGRIWEISS e5 Vision Kit",
      description: "Agricultural-focused solution combining AGRIWEISS e5 with Grove Vision AI V2 and Raspberry Pi OV5647 Camera Module for smart farming applications.",
      learnMoreUrl: "https://www.seeedstudio.com/",
      altText: "AGRIWEISS e5 Vision Kit"
    },
    {
      image: esp32c3Img,
      title: "XIAO ESP32C3 Vision Kit",
      description: "Compact vision system featuring XIAO ESP32C3 combined with Grove Vision AI V2 and Raspberry Pi OV5647 Camera Module for efficient edge computing.",
      learnMoreUrl: "https://www.seeedstudio.com/",
      altText: "XIAO ESP32C3 Vision Kit"
    },
    {
      image: esp32cSim800lImg,
      title: "XIAO ESP32C3 Cellular Kit",
      description: "Complete cellular IoT vision solution with XIAO ESP32C3, SIMCOM 800L, Grove Vision AI V2, and Raspberry Pi OV5647 Camera Module for remote monitoring.",
      learnMoreUrl: "https://www.seeedstudio.com/",
      altText: "XIAO ESP32C3 Cellular Kit"
    }
  ];

  return (
    <div className="py-12 sm:py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-[#ed4c4c] mb-4">Supported Devices</h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-12">
            Our platform supports a growing ecosystem of IoT vision devices, 
            enabling you to leverage the latest in edge computing and AI technology.
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {devices.map((device, index) => (
            <div 
              key={index}
              className="card bg-white hover:shadow-2xl transition-all hover:-translate-y-2 border border-gray-100"
            >
              <div className="card-body items-center text-center p-8">
                <div className="bg-white rounded-full p-8 mb-6">
                  <img 
                    src={device.image}
                    alt={device.altText}
                    className="w-24 sm:w-32 h-24 sm:h-32 object-contain transform transition-transform hover:scale-110"
                  />
                </div>
                <h3 className="card-title text-[#ed4c4c] text-xl sm:text-2xl">
                  {device.title}
                </h3>
                <p className="text-gray-700 my-4">
                  {device.description}
                </p>
                <div className="card-actions mt-4">
                  <a 
                    href={device.learnMoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn bg-[#ed4c4c] text-white hover:bg-[#c43c3c] transform transition hover:scale-105"
                  >
                    Learn More
                    <ChevronRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Devices;