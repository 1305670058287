// src/components/landing/Hero.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

interface HeroProps {
  phrases: string[];
  subPhrases: string[];
}

/**
 * Hero section component for the landing page.
 * Displays animated text and call-to-action button.
 *
 * @param {HeroProps} props - The props for the Hero component
 * @returns {JSX.Element} The rendered Hero component
 */
const Hero: React.FC<HeroProps> = ({ phrases, subPhrases }) => {
  const [currentPhrase, setCurrentPhrase] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prev) => (prev + 1) % phrases.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [phrases.length]);

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-16">
            <div className="text-center relative">
              {/* Recording Status Indicator - Fixed for mobile */}
              <div className="absolute top-[-30px] sm:top-0 left-4 sm:left-8 flex items-center space-x-2">
                <div className="w-3 h-3 bg-[#ed4c4c] rounded-full animate-pulse" />
                <span className="text-sm text-gray-600">Recording..</span>
              </div>
              
              <h2 className="text-3xl tracking-tight font-extrabold sm:text-5xl md:text-6xl min-h-[160px] sm:min-h-[128px] mt-8 sm:mt-0">
                <span className="block transition-opacity duration-500 animate-fade-in">
                  {phrases[currentPhrase]}
                </span>
                <span className="block text-[#ed4c4c] transition-opacity duration-500 animate-fade-in">
                  {subPhrases[currentPhrase]}
                </span>
              </h2>
              <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl text-gray-700">
                Deploy, manage, and analyze your IoT camera fleet with ease. 
                Powered by advanced Vision AI technology.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                <div className="rounded-md shadow">
                  <Link
                    to="/signup"
                    className="btn bg-[#ed4c4c] btn-lg text-white hover:bg-[#c43c3c] transform transition hover:scale-105"
                  >
                    Start Free Trial
                    <ChevronRight className="ml-2 animate-bounce" />
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Hero;