// src/layouts/MainLayout.tsx
import React from 'react';
import Navigation from '../components/landing/Navigation';
import Footer from '../components/landing/Footer';
import BuyMeCoffeeWidget from '../components/landing/BuyMeCoffeeWidget';
import icon from '../assets/icon.svg';

interface MainLayoutProps {
  children: React.ReactNode;
}

/**
 * Main layout component that provides consistent navigation and footer.
 * Used as a wrapper for main content pages.
 *
 * @param {MainLayoutProps} props - The component props
 * @returns {JSX.Element} The rendered layout
 */
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navigation icon={icon} />
      <main className="flex-grow">
        {children}
      </main>
      <Footer icon={icon} />
      <BuyMeCoffeeWidget />
    </div>
  );
};

export default MainLayout;