// src/pages/About.tsx
import React from 'react';
import MainLayout from '../layouts/MainLayout';

/**
 * About page component.
 * Displays company information and mission.
 *
 * @returns {JSX.Element} The rendered About page
 */
const About: React.FC = () => {
  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            About VideoBase
          </h1>
          <p className="text-xl text-gray-600">
            Building the future of IoT vision technology
          </p>
        </div>
        
        {/* Add your about page content here */}
      </div>
    </MainLayout>
  );
};

export default About;