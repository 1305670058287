// src/pages/Docs.tsx
import React from 'react';
import MainLayout from '../layouts/MainLayout';

/**
 * Documentation page component.
 * Provides technical documentation and guides.
 *
 * @returns {JSX.Element} The rendered Docs page
 */
const Docs: React.FC = () => {
  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Documentation
          </h1>
          <p className="text-xl text-gray-600">
            Learn how to integrate and use VideoBase
          </p>
        </div>

        {/* Add your documentation content here */}
      </div>
    </MainLayout>
  );
};

export default Docs;