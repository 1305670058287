import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings as SettingsIcon, User, Bell, Lock, CreditCard, LogOut } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const CheckLoading: FC = () => (
  <div className="flex justify-center items-center min-h-screen">
    <span className="loading loading-spinner loading-lg text-primary"></span>
  </div>
);

const Settings: FC = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [notifications, setNotifications] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const BILLING_API = import.meta.env.VITE_PROD_BILLING_API;

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (user?.email) {
        try {
          const response = await fetch(
            `${BILLING_API}/api/subscriptions/status/${encodeURIComponent(user.email)}`,
            {
              credentials: 'include',
            }
          );
          const data = await response.json();
          console.log('Subscription data:', data);
          setSubscriptionStatus(data);
        } catch (err) {
          console.error('Error fetching subscription:', err);
          setError('Failed to load subscription information');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [user, BILLING_API]);

  const handleManageSubscription = async () => {
    try {
      const response = await fetch(
        `${BILLING_API}/api/subscriptions/create-portal-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user?.id,
            email: user?.email,
          }),
          credentials: 'include',
        }
      );

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (err) {
      console.error('Error accessing billing portal:', err);
      setError('Failed to access billing portal');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Logout error:', err);
      setError('Failed to log out');
    }
  };

  const handleSaveChanges = async () => {
    if (password || confirmPassword) {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      if (password.length < 8) {
        setError('Password must be at least 8 characters long');
        return;
      }
      try {
        // Implement password change logic here
        console.log('Password updated');
      } catch (err) {
        console.error('Error updating password:', err);
        setError('Failed to update password');
      }
    }
  };

  if (loading) {
    return <CheckLoading />;
  }

  return (
    <div className="min-h-screen bg-base-200">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex items-center gap-2 mb-6">
          <SettingsIcon className="w-6 h-6 text-primary" />
          <h1 className="text-2xl font-bold">Settings</h1>
        </div>

        {error && (
          <div className="alert alert-error mb-4">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Account Settings */}
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Account Settings</h2>
              <div className="flex items-center gap-4 mb-4">
                <User className="w-6 h-6 text-primary" />
                <div>
                  <label className="font-medium">
                    Email
                  </label>
                  <p className="mt-1 opacity-70">{user?.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <User className="w-6 h-6 text-primary" />
                <div>
                  <label className="font-medium">
                    User ID
                  </label>
                  <p className="mt-1 opacity-70">{user?.id}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Notification Settings */}
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Notification Settings</h2>
              <div className="flex items-center gap-4">
                <Bell className="w-6 h-6 text-primary" />
                <div className="flex items-center gap-4">
                  <label htmlFor="notifications" className="font-medium">
                    Enable Notifications
                  </label>
                  <input
                    type="checkbox"
                    id="notifications"
                    checked={notifications}
                    onChange={(e) => setNotifications(e.target.checked)}
                    className="toggle toggle-primary"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Security Settings */}
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Security Settings</h2>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <Lock className="w-6 h-6 text-primary" />
                  <div className="flex-1">
                    <label htmlFor="password" className="font-medium">
                      New Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input input-bordered w-full mt-1"
                      placeholder="Enter new password"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <Lock className="w-6 h-6 text-primary" />
                  <div className="flex-1">
                    <label htmlFor="confirmPassword" className="font-medium">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="input input-bordered w-full mt-1"
                      placeholder="Confirm new password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Billing Settings */}
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Subscription Details</h2>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <CreditCard className="w-6 h-6 text-primary" />
                  <div>
                    <h3 className="font-medium">Current Plan</h3>
                    <p className="opacity-70">
                      {subscriptionStatus?.userPlanName || 'No Active Plan'}
                      {subscriptionStatus?.hasActiveSubscription && 
                        <span className="badge badge-success ml-2">Active</span>
                      }
                    </p>
                  </div>
                </div>
                
                {subscriptionStatus?.subscriptionId && (
                  <div className="flex items-center gap-4">
                    <CreditCard className="w-6 h-6 text-primary" />
                    <div>
                      <h3 className="font-medium">Subscription ID</h3>
                      <p className="opacity-70">{subscriptionStatus.subscriptionId}</p>
                    </div>
                  </div>
                )}

                <button
                  onClick={handleManageSubscription}
                  className="btn btn-primary w-full"
                >
                  Manage Subscription
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Save Changes and Logout */}
        <div className="mt-6 flex justify-between">
          <button
            onClick={handleSaveChanges}
            className="btn btn-primary"
            disabled={!password && !confirmPassword}
          >
            Save Changes
          </button>

          <button 
            onClick={handleLogout}
            className="btn btn-outline btn-error"
          >
            <LogOut className="w-5 h-5 mr-2" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;